<script setup>
  import {email_dispatcher} from "@/mixins/email_dispatcher";
  import { useWebStore } from '@/store/web.js'
  const store = useWebStore();
  const props = defineProps(['contactPhone','contactEmail', 'contactTopic','attachment']);
  const { $emailClient } = useNuxtApp()
  let popoverVisible = ref(false);
  let name = ref('')
  let email = ref('')
  let message = ref('')
  let telephone = ref('')
  let thanks = false

  const missingInfo = computed(() =>{
      let missing = [];
      (name.value.length == 0 )&&missing.push(store.getLocaleString('cup10'));
      (email.value.length == 0)&&missing.push(store.getLocaleString('cup11'));
      (telephone.value.length == 0)&&missing.push(store.getLocaleString('cup12'));
      return missing;
    });

  //METHODS
  function showPopover() {
    if (missingInfo.value.length > 0) {
      console.log('HALO')
      popoverVisible.value = true;
    }
  }

  function hidePopover() {
    popoverVisible.value = false;
  }

  function restrictInput(event) {
      // Allow only numeric input and '+'
      if (!/[0-9+]/.test(event.key)) {
        event.preventDefault();
      }
  }

  async function sendEmail() {
    if (missingInfo.value.length === 0) {
      try {
        const emailData = {
          message: message.value,
          name: name.value,
          email: email.value,
          telephone: telephone.value,
        };

        // Add attachment only if it's defined in props
        if (props.attachment !== undefined) {
          emailData.attachment = props.attachment;
        }

        const response = await $emailClient('/api/email', {
          body: emailData
        }, {
          type: props.contactTopic
        });

        if(response === 'Message sent successfully'){
          navigateTo('/thanks')
        }
      } catch (error) {
        console.error('Error sending email', error);
      }
    }
  }

  function validateTelephone(event) {
      // Remove non-numeric and non-'+' characters
      event.target.value = event.target.value.replace(/[^\d+]/g, '');
      this.telephone = event.target.value; // Update the model
  }

  function preventNonNumericPaste(event) {
    // Prevent non-numeric and non-'+' paste
    const paste = event.clipboardData.getData('text');
    if (!/^[\d+]+$/.test(paste)) {
      event.preventDefault();
    }
  }

</script>
<template>
  <div
      id="enquiry"
      class="grid gap-0 mx-auto grid-cols-1 sm:grid-cols-2 xl:w-3/4"
  >
    <div class="bgDark bg-image-circle hidden sm:block">
      <div class="middleMargin w80per leftText p-top-230 p-bot-230">
        <text-with-line-after :text="store.getLocaleString('cup1')" />
        <h6 class="text-3xl uppercase InterMedium ls-03 mt-5">{{store.getLocaleString('cup2')}}</h6>
        <p class="largeText">{{store.getLocaleString('cup3')}}</p>
        <a
            :href="'tel:' + (contactPhone === undefined ? '+36 76 448 544' : contactPhone)"
            class="xLargeText no-decoration InterMedium fw-700 m0 m-top-20 orangeText"
        >{{ contactPhone === undefined ? '+36 76 448 544' : contactPhone }}</a>
      </div>
    </div>

    <!--FORM-->
    <div class="grid gap-7 text-left grid-cols-1 bg-light-grey bg-image-industry p-5">
      <span>
        <text-with-line-after :text="store.getLocaleString('cup4')" />
        <h5 class="text-4xl uppercase m0 SpaceGrotesk font-semibold mt-5">{{store.getLocaleString('cup5')}}</h5>
      </span>
      <input v-model="name" required class="input smallText tr-1s m-top-20" type="text" :placeholder="store.getLocaleString('cup6')" />
      <!--<input v-model="company" class="input smallText tr-1s m-top-20" type="text" placeholder="Cégnév / Iparág" />-->
      <input v-model="email" required class="input smallText tr-1s m-top-20" type="email" placeholder="E-mail*" />
      <input
          v-model="telephone"
          required
          class="input smallText tr-1s m-top-20"
          type="tel"
          :placeholder="store.getLocaleString('cup7')"
          @keypress="restrictInput"
          @input="validateTelephone"
          @paste="preventNonNumericPaste"
      />
      <textarea v-model="message" required class="input textArea m-top-20 smallText tr-1s" type="text" :placeholder="store.getLocaleString('cup8')"></textarea>

      <div class="relative inline-block">
        <read-more-button
            @mouseenter="showPopover()"
            @mouseleave="hidePopover()"
            @click="sendEmail"
            :bordered="true"
            :disabled="missingInfo.length > 0"
            class="rightGridElement"
            :text="store.getLocaleString('cup9')"
            identification="eqp0"
            color="black"
            :orange="true"
        />
        <client-only>
          <pop-over ref="popover" v-show="popoverVisible">
            <template v-show="missingInfo.length">
              <ul>
                <li v-for="(info, index) in missingInfo" :key="index" class="p15">{{ info }}</li>
              </ul>
            </template>
          </pop-over>
      </client-only>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.relative {
  position: relative;
}
.inline-block {
  display: inline-block;
}
</style>
